<template>
  <div class="tinymce-editor">
    <editor :id="tinymceId" v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick"> </editor>
    <!-- <ImageDialog :dialog="imageDialog" @confirm="handleImageConfirm" /> -->
    <!-- <VideoDialog :dialog="videoDialog" /> -->
    <!-- <div class="editor-custom-btn-container">
      <editorImage color="#1890ff" class="editor-upload-btn" @successCBK="imageSuccessCBK" />
    </div> -->
    <UploadImg :dialog="uploadImgDialog" @successCBK="uploadImgSuccessCBK" />
  </div>
</template>
<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'

import { iconList, btnList } from './registryUI/const'
//样式
import 'tinymce/skins/content/default/content.min.css'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
// import VideoDialog from './components/VideoDialog.vue'
// import ImageDialog from './components/ImageDialog.vue'
// import editorImage from './components/EditorImage'
import UploadImg from './components/UploadImg'

export default {
  components: {
    Editor,
    // ImageDialog,
    // VideoDialog,
    // editorImage,
    UploadImg
  },
  props: {
    id: {
      type: String,
      default: function() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      }
    },
    value: {
      type: String,
      default: ''
    },
    // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
    // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
    baseUrl: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 500
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      // autoresize
      default:
        'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em  lineheight axupimgs quickbars ax_wordlimit wordcount letterspacing formatpainter'
    },
    toolbar: {
      type: [String, Array],
      default:
        'CardBtn letterspacing | Btnundo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist |   table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |'
    }
  },
  data() {
    return {
      tinymceId: this.id,
      init: {
        language: 'zh_CN',
        // height: this.height, //编辑器高度
        min_height: this.height,
        plugins: this.plugins,
        // toolbar: this.toolbar,
        toolbar1: 'code undo redo restoredraft | fontselect fontsizeselect | forecolor backcolor bold italic underline strikethrough anchor | ',
        toolbar2: 'cut copy paste pastetext | alignleft aligncenter alignright alignjustify outdent indent indent2em lineheight letterspacing | bullist numlist hr | ',
        // VideoBtn
        toolbar3: 'subscript superscript removeformat formatpainter | table insertdatetime blockquote charmap emoticons ImageBtn  link bdmap wordcount codesample preview|',
        branding: false,
        menubar: false, //顶部菜单栏显示
        contextmenu: false,
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        font_formats:
          '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
        quickbars_insert_toolbar: '',
        content_style: 'p {margin: 0;}',
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage ',
        ax_wordlimit_num: 20, //字数限制
        ax_wordlimit_callback: function(editor, txt, num) {
          console.log('当前字数：' + txt.length + '，限制字数：' + num)
        },
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success, failure) => {
          const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          success(img)
        },
        setup: editor => {
          // console.log('setup')
          this.registryIcon(editor)
          this.registryBtn(editor)
          this.$on('ImageBtnClick', _ => {
            // this.imageDialog.dialogVisible = true
            this.uploadImgDialog.dialogVisible = true
          })
          this.$on('VideoBtnClick', _ => {
            this.videoDialog.dialogVisible = true
          })
        }
      },
      myValue: this.value,
      imageDialog: {
        title: '图片素材库',
        dialogVisible: false
      },
      videoDialog: {
        title: '视频素材库',
        dialogVisible: false
      },
      uploadImgDialog: {
        dialogVisible: false
      }
    }
  },
  mounted() {
    tinymce.init({})
  },
  methods: {
    uploadImgSuccessCBK(arr) {
      arr.forEach(v => {
        tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`)
      })
    },
    getBase64Image(url) {
      return new Promise((resolve, reject) => {
        var Img = new Image()
        var dataURL = ''
        Img.setAttribute('crossOrigin', 'Anonymous')
        Img.src = url + '?v=' + Math.random()
        Img.onload = function() {
          // 要先确保图片完整获取到，这是个异步事件
          var canvas = document.createElement('canvas') // 创建canvas元素
          var width = Img.width // 确保canvas的尺寸和图片一样
          var height = Img.height
          canvas.width = width
          canvas.height = height
          canvas.getContext('2d').drawImage(Img, 0, 0, width, height) // 将图片绘制到canvas中
          dataURL = canvas.toDataURL('image/jpeg') // 转换图片为dataURL
          resolve(dataURL)
        }
      })
    },
    async handleImageConfirm(image) {
      this.$confirm
      this.imageDialog.dialogVisible = false
      // ! base64的图片在富文本中才有编辑操作
      // let base64Image = await this.getBase64Image(image.url)
      tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${image.url}" />`)
    },
    // 注册icon
    registryIcon(editor) {
      iconList.forEach(item => {
        editor.ui.registry.addIcon(item.name, item.svg)
      })
    },
    registryBtn(editor) {
      btnList.forEach(item => {
        editor.ui.registry.addButton(item.name, {
          icon: item.icon,
          tooltip: item.tooltip || item.name,
          onAction: _ => {
            /**
             * 获取事件
             * this.$on(name,funcion)
             */
            this.$emit(`${item.name}Click`, Object.assign({ name: item.name }, _))
          }
        })
      })
    },
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick(e) {
      this.$emit('onClick', e, tinymce)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
    },
    // 设置选中的文本
    setSelectionContent(value) {
      tinymce.activeEditor.selection.setContent(value)
    },
    // 获取选中的文本
    getSelectionContent() {
      return tinymce.activeEditor.selection.getContent()
    },
    // 设置文本
    setContent(value) {
      tinymce.get(this.tinymceId).setContent(value)
    },
    // 获取文本
    getContent() {
      return tinymce.get(this.tinymceId).getContent()
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
    },
    myValue(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
<style lang="less">
.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 2005;
}
</style>
