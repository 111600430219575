import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 上传文件
 * @param {*} file 上传的文件
 * @param {contentType} 'form-data'
 * @returns
 */
export const uploadImg = formData => {
  return awaitWrap(
    request({
      url: '/upload/uploadImg',
      method: 'POST',
      data: formData,
      contentType: 'multipart/form-data'
    })
  )
}
/**
 * 多图片上传到七牛云
 * @param {Array} file 上传的文件
 * @param {contentType} 'form-data'
 * @returns
 */
export const batchUploadImg = formData => {
  return awaitWrap(
    request({
      url: '/upload/batchUploadImg',
      method: 'POST',
      data: formData,
      contentType: 'multipart/form-data'
    })
  )
}