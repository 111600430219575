<template>
  <div class="upload-container">
    <el-dialog :visible.sync="dialog.dialogVisible" @closed="handleClose" width="70%">
      <el-upload
        ref="upload"
        accept="image/*"
        :multiple="true"
        :file-list="fileList"
        :show-file-list="true"
        :headers="uploadHeaders"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-upload="beforeUpload"
        :http-request="uploadFile"
        :auto-upload="false"
        class="editor-slide-upload"
        action=""
        list-type="picture-card"
      >
        <el-button size="small" type="primary">
          点击上传
        </el-button>
      </el-upload>
      <div slot="footer" class="dialog-footer margin-right-10">
        <span>
          <el-button @click="dialog.dialogVisible = false">
            取消
          </el-button>
          <el-button type="primary" @click="handleSubmit">
            确认
          </el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadImg, batchUploadImg } from '@/api/uploadController'
import { baseApi } from '@/config'

export default {
  name: 'UploadImg',
  props: {
    dialog: {
      type: Object,
      default: () => {
        return {
          dialogVisible: false
        }
      }
    }
  },
  data() {
    return {
      uploadHeaders: {},
      listObj: {},
      fileList: [],
      files: []
    }
  },
  methods: {
    checkAllSuccess() {
      return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
    },
    async handleSubmit() {
      this.loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$refs.upload.submit() // 这里是执行文件上传的函数，其实也就是获取我们要上传的文件
      this.handleUpload(this.files)
      return
    },
    async handleUpload(files) {
      // console.log(files.length)
      if (files.length == 0) {
        this.loading.close()
        // console.log('上传成功')
        // console.log(this.fileList)
        this.$emit('successCBK', this.fileList)
        this.dialog.dialogVisible = false
        return
      }
      const fileUid = this.files[0].uid
      let fromData = new FormData()
      fromData.append('file', this.files[0])
      let [err, res] = await uploadImg(fromData)
      if (err) {
        console.log(err)
        this.loading.close()
        return this.$message.error(err.message || '上传失败')
      }
      this.listObj[fileUid].url = res.data
      this.listObj[fileUid].hasSuccess = true
      this.fileList = Object.keys(this.listObj).map(v => this.listObj[v])
      files.splice(0, 1)
      return this.handleUpload(files)
    },
    handleRemove(file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          this.fileList.splice(i, 1)
          return
        }
      }
    },
    beforeUpload(file) {
      // const _self = this
      // const _URL = window.URL || window.webkitURL
      // const fileName = file.uid
      // this.listObj[fileName] = {}
      // return new Promise((resolve, reject) => {
      //   const img = new Image()
      //   img.src = _URL.createObjectURL(file)
      //   img.onload = function() {
      //     _self.listObj[fileName] = { hasSuccess: false, uid: file.uid,  width: this.width, height: this.height }
      //   }
      //   resolve(true)
      // })
    },
    async uploadFile(params) {
      const { file } = params
      const fileName = file.uid
      this.listObj[fileName] = { hasSuccess: false, uid: file.uid, name: file.name }
      this.files.push(file)
    },
    handleSuccess(response, file) {
      // const uid = file.uid
      // const objKeyArr = Object.keys(this.listObj)
      // for (let i = 0, len = objKeyArr.length; i < len; i++) {
      //   if (this.listObj[objKeyArr[i]].uid === uid) {
      //     this.listObj[objKeyArr[i]].url = response.data
      //     this.listObj[objKeyArr[i]].hasSuccess = response.data ? true : false
      //     return
      //   }
      // }
    },
    getImgSize(file, fileName) {
      const _self = this
      const _URL = window.URL || window.webkitURL
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = function() {
          resolve({
            fileUid: fileName,
            width: this.width,
            height: this.height
          })
        }
      })
    },
    handleClose() {
      this.fileList = []
      this.listObj = {}
    }
  }
}
</script>

<style lang="less" scoped>
.editor-slide-upload {
  margin-bottom: 20px;
  ::v-deep .el-upload--picture-card {
    // width: 100%;
  }
}
</style>
